import list from 'assets/icon-app-bar/list.svg'
import home from 'assets/icon-app-bar/home.svg'
import user from 'assets/icon-app-bar/user.svg'
import call from 'assets/icon-call.svg'
import close from 'assets/icon-close.svg'
import author from 'assets/icon-author.svg'
import address from 'assets/icon-address.svg'
import volume from 'assets/icon-volume.svg'
import date from 'assets/app-date.svg'
import selected from 'assets/my-city/icon-city.svg'
import empty from 'assets/empty.svg'

// icon profile
import bell from 'assets/icon-profile/bell.svg'
import exit from 'assets/icon-profile/exit.svg'
import faq from 'assets/icon-profile/faq.svg'
import lock from 'assets/icon-profile/lock.svg'
import pin from 'assets/icon-profile/pin.svg'
import reload from 'assets/icon-profile/reload.svg'
import support from 'assets/icon-profile/support.svg'
import tarif from 'assets/icon-profile/tarif.svg'
import check from 'assets/icon-profile/check.svg'
import applications from 'assets/icon-profile/applications.svg'
import altAvatar from 'assets/alt-avatar.svg'
import selectAvatar from 'assets/icon-profile/avatar-select-icon.svg'
import lang from 'assets/icon-profile/lang.svg'
// icon support
import contact from 'assets/icon-support/contact.svg'
import myOrder from 'assets/icon-support/my-order.svg'
import newOrder from 'assets/icon-support/new-order.svg'

// icon security
import pinLock from 'assets/icon-security/pin-lock.svg'
import key from 'assets/icon-security/key.svg'
import imprint from 'assets/icon-security/imprint.svg'
import eye from 'assets/icon-security/eye.svg'
import closeEye from 'assets/icon-security/close-eye.svg'

// icon contacts
import phone from 'assets/icon-contacts/phone.svg'
import whatsapp from 'assets/icon-contacts/whatsapp.svg'
import mail from 'assets/icon-contacts/mail.svg'

import arrowRight from 'assets/icon/right-arrow.svg'
import arrowLeft from 'assets/icon/left-arrow.svg'
import plus from 'assets/icon/plus.svg'

//register
import info from 'assets/icon-info.svg'
import seller from 'assets/icon-seller.svg'
import buyer from 'assets/icon-buyer.svg'

//pin
import faceId from 'assets/pin/faceId.svg'
import finger from 'assets/pin/pin-finger.svg'
import closePin from 'assets/pin/close-icon.svg'

import logo from 'assets/logo.svg'

//hello screen
import hello0 from 'assets/hello-screen/hello0.svg'
import hello1 from 'assets/hello-screen/hello1.svg'
import hello2 from 'assets/hello-screen/hello2.svg'
import hello3 from 'assets/hello-screen/hello3.svg'

//app
import appDescription from 'assets/app-icon-description.svg'
import appCategoryInfo from 'assets/app-category-description.svg'

export default {
  author,
  address,
  volume,
  date,
  check,
  selected,
  eye,
  call,
  close,
  'close-eye': closeEye,
  phone,
  whatsapp,
  mail,
  plus,
  bell,
  exit,
  faq,
  lock,
  pin,
  reload,
  support,
  tarif,
  applications,
  'right-arrow': arrowRight,
  'left-arrow': arrowLeft,
  list,
  home,
  user,
  empty,
  contact,
  'my-order': myOrder,
  'new-order': newOrder,
  'pin-lock': pinLock,
  key,
  imprint,
  info,
  buyer,
  seller,
  faceId,
  finger,
  closePin,
  altAvatar,
  selectAvatar,
  lang,
  logo,
  hello0,
  hello1,
  hello2,
  hello3,
  appDescription,
  appCategoryInfo,
}
