export default {
  app: {
    state: {
      lang: localStorage.getItem('locale') || 'ru',
      translates: {},
      load: false,
      _save: [ 'lang', 'translates', ],
    },
    getters: {
      translates(state) {
        return state.translates
      },
      lang(state) {
        return state.lang
      },
      load(state) {
        return state.load
      },
    },
    mutations: {
      lang(state, value) {
        state.lang = value
      },
      translates(state, value) {
        state.translates = value
      },
      load(state, value) {
        state.load = value
      },
    },
    actions: {
      updateUser() {
        return $api.auth.info().then((response) => {
          $app.auth.user(response.data.content.user)
        })
      },
      initLang({ state, dispatch, }) {
        localStorage.getItem('locale') ? dispatch('setLang', localStorage.getItem('locale')) : dispatch('setLang', state.lang)
      },
      setLang({ state, commit, }, value) {
        $app.api.config({
          headers: {
            'Locale': value,
          },
        })
        localStorage.setItem('locale', value)
        commit('lang', value)
        commit('load', true)
        $api.lang.items().then((response) => {
          commit('translates', response.data.content )
          $app.api.config({
            headers: {
              'Locale': localStorage.getItem('locale'),
            },
          })
        }).finally(() => {
          commit('load', false)
        })
      },
    },
    namespaced: true,
  },
}
