<template>
  <div class="n-image-upload">
    <div id="addImage" class="image" @click="selectFile">
      <div id="preview">
        <img v-if="url" :src="url" alt=""/>
        <img v-if="!url && defaultI" class="url" :src="defaultI" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
import {Camera, CameraResultType, CameraSource,} from '@capacitor/camera'

export default {
  name: 'NImageUpload',
  props: {
    file: {type: [File, Array,],},
    defaultI: {type: String, default: '',},
    from: {type: String, default: 'gallery',}, // both, camera, gallery
  },
  data() {
    return {
      url: this.defaultI,
    }
  },
  created() {
  },
  methods: {
    selectFile() {
      Camera.checkPermissions().then((res) => {
        if (res.camera !== 'granted') {
          Camera.requestPermissions().then(() => {
            this.selectFile()
          })
        } else {
          const mapper = {
            both: CameraSource.Prompt,
            camera: CameraSource.Camera,
            gallery: CameraSource.Photos,
          }
          Camera.getPhoto({
            source: mapper[this.from],
            quality: 90,
            resultType: CameraResultType.DataUrl,
          }).then((response) => {
            const file = this.dataURLtoFile(response.dataUrl, 'image' + this.file.length )
            this.url = URL.createObjectURL(file)
            this.$emit('update:file', file)
          })
        }
      })
    },
    dataURLtoFile(dataUrl, filename) {
      const parts = dataUrl.split(',')
      const mime = parts[0].match(/:(.*?);/)[1]
      const fileContent = atob(parts[1])
      let n = fileContent.length
      const bitArray = new Uint8Array(n)
      while (n--) {
        bitArray[n] = fileContent.charCodeAt(n)
      }

      return new File([bitArray,], filename, {type: mime,})
    },
  },
}
</script>

<style lang="scss" scoped>
.n-image-upload {
  .image {
    background-color: #d9d7d7;
    height: 170px;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;

    input {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      height: auto;
      margin: 0;
      border-radius: 0;
      opacity: 0;
    }
  }

  #preview {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    &::after {
      content: '';
      position: absolute;
      background-image: url("./../../assets/image-upload.png");
      width: 51px;
      height: 51px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  #preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;
  }

  .url {
    height: 100%;
  }
}
</style>
