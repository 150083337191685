<template>
  <div class="top-bar" :class="{'auth-page' : isAuth}">
    <div class="container">
      <div class="wrap">
        <div v-if="back" class="back" @click="$emit('click')">
          <n-link v-if="params === null" :to="{name: backroute}"></n-link>
          <n-link v-else :to="{name: backroute, params: params}"></n-link>
          <n-icon icon="left-arrow" />
        </div>
        <div class="title">{{ title }}</div>
        <div v-if="img.length !== 0" class="img"><n-icon icon="logo" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  props: {
    title: { type: String, required: false, },
    isAuth: { type: Boolean, default: false, },
    backroute: { type: String, required: false, default: '', },
    back: { type: Boolean, default: false, },
    params: { type: Object, default: null, },
    img: { type: String, default: '', },
  },
}
</script>

<style scoped lang="scss">
.top-bar {
  background: #FFF;
  border-bottom: 1px solid #EFEFEF;
  padding: 10px 0;
  position: fixed;
  left: 0;
  right: 0;
  top: calc(env(safe-area-inset-top));
  height: 50px;
  z-index: 10;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 100px;
    top: -100px;
    background: #fff;
  }

  &.auth-page{
    border-bottom: none;
    height: 90px;
    padding: 25px 0;
  }
  .img {
    width: 110px;
    height: 40px;

    ::v-deep svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .back {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      display: flex;
      align-items: center;
      justify-content: center;

      .n-link {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
    }
  }

  .title {
    font-weight: 500;
    font-size: 18px;
    color: #1E1E1E;
  }
}
</style>
