<template>
  <div class="change-pin">
    <div class="container">
      <c-pin v-if="step === 0" :danger-message="$var('danger')" :title="$app.secure.clean($n.t('4.2'))" :value.sync="code1" @complete="step = 1" />
      <c-pin v-if="step === 1" :danger-message="$var('danger')" :title="$app.secure.clean($n.t('4.3'))" :value.sync="code2" @complete="step = 2" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageIndex',
  data() {
    return {
      code1: '',
      code2: '',
      step: 0,
      auth: JSON.parse(localStorage.getItem('auth')),
    }
  },
  watch: {
    step() {
      if (this.step === 2) {
        if (this.code1 === this.code2) {
          localStorage.setItem('pin', this.code1)
          this.setUserPin(true)
        } else {
          this.$var('danger', $app.secure.clean($n.t('20.1')))
          this.step = 0
          this.code1 = ''
        }
      }
      if (this.step === 1) {
        this.code2 = ''
        this.$var('danger', '')
      }
    },
  },
  created() {
  },
  methods: {
    setUserPin(value) {
      this.$var('load', true)
      $api.user.edit(this.auth.user.id, { usePinCode: value, }).then(() => {
        $app.store.action('app.updateUser').finally(() => {
          this.$emit('update:show', false)
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.change-pin {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

}
</style>
