import AppBar from './app-bar/Index'
import TopBar from './top-bar/Index'
import CRadio from './c-radio/Index'
import CCallApp from './call-app/Index'
import CAddressSelect from './address-select/Index'
import CodeInput from './code-input/Index'
import CPin from './pin/Index'
import CNewPin from './new-pin/Index'
import T from './t/Index'
import CImageUpload from './image-upload/Index.vue'

export default {
  AppBar,
  TopBar,
  CRadio,
  CCallApp,
  CAddressSelect,
  CodeInput,
  CPin,
  CNewPin,
  T,
  CImageUpload,
}
