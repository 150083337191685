<template>
  <div class="app-bar">
    <div class="container">
      <ul>
        <li>
          <n-link :class="routName === 'orders' ? 'active' : ''" :to="{name: 'orders'}">
            <div class="img">
              <n-icon icon="list"/>
            </div>
            <t name="4.5" />
          </n-link>
        </li>
        <li>
          <n-link :class="routName === 'index' ? 'active' : ''" :to="{name: 'index'}">
            <div class="img">
              <n-icon icon="home"/>
            </div>
            <t name="4.1" />
          </n-link>
        </li>
        <li>
          <n-link :class="routName === 'profile' ? 'active' : ''" :to="{name: 'profile'}">
            <div class="img">
              <n-icon icon="user"/>
            </div>
            <t name="10.1" />
          </n-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppBar",
  computed: {
    routName() {
      return this.$route.name
    }
  }
}
</script>

<style scoped lang="scss">
.app-bar {
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  left: 0;
  right: 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
  background: #fff;
  z-index: 10;

  &:after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    bottom: -100%;
    background: #fff;
  }

  ul {
    height: 60px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    list-style: none;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 13px;
        color: #000;


        &.active {

          .img::v-deep{
            svg{
              path{
                fill: #52C41A;
              }
            }
          }
        }
      }

      .img {
        height: 22px;
        width: 22px;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          //width: 100%;
        }
      }
    }
  }
}
</style>
