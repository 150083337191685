<template>
  <div class="c-pin" :class="[ {'c-hidden': hidden,}]">
    <div class="pin-numbers">
      <input
        v-for="i in 4" :key="i" placeholder="1" :type="needHide?'password':'tel'"
        :class="{'danger': !!dangerMessage}" readonly :value="s_value[i - 1]">
    </div>
    <div class="inputs-subtitle">
      <div v-if="dangerMessage" class="danger-message">{{ dangerMessage }}</div>
      <slot v-else name="title">{{ title }}</slot>
    </div>
    <div class="keyboard">
      <div v-for="number in 9" :key="number" class="item" @click="numberClick(number)">
        <div class="content">{{ number }}</div>
      </div>
      <div :class="{'hide': !needFinger}" class="item" @click="finger">
        <div class="content icon">
          <n-icon v-if="isFinger" class="finger-icon" icon="finger" alt="" />
          <n-icon v-else class="finger-icon" icon="faceId" alt="" />
        </div>
      </div>
      <div class="item" @click="numberClick(0)">
        <div class="content">0</div>
      </div>
      <div class="item" @click="backspace">
        <div class="content icon"><n-icon class="img-delete" icon="closePin" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Capacitor, } from '@capacitor/core'

export default {
  name: 'CPin',
  props: {
    hidden: { type: Boolean, default: false, },
    value: { type: String, default: '', },
    useTouch: { type: Boolean, default: false, },
    title: { type: String, default: () => $app.secure.clean($n.t('21.1')), },
    needFinger: { type: Boolean, default: false, },
    pushTouch: { type: Boolean, default: false, },
    dangerMessage: { type: String, default: '', },
    needHide: { type: Boolean, default: false, },
  },
  data() {
    return {
      s_value: this.valueToArray(),
      numbers: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, ],
      isFinger: true,
    }
  },
  watch: {
    value() {
      this.s_value = this.valueToArray()
    },
    pushTouch() {
      if (this.pushTouch) {
        this.finger()
      }
    },
    useTouch() {
      if (this.useTouch) {
        this.finger()
      }
    },
  },
  created() {
    if (this.useTouch || this.pushTouch) {
      this.finger()
    }
    if (Capacitor.getPlatform() !== 'web') {
      Fingerprint.isAvailable(this.isAvailableSuccess)
    }
  },
  methods: {
    isAvailableSuccess(result) {
      if (result === 'face') {
        this.isFinger = false
      } else if (result === 'finger' || result === 'biometric') {
        this.isFinger = true
      }
    },
    valueToArray() {
      return this.value ? $n.reduce(this.value, (result, item) => {
        result.push(item)
        return result
      }, []) : []
    },
    numberClick(number) {
      if (this.s_value.length < 4) {
        const value = [ ...this.s_value, ]
        value.push(number)
        this.s_value = value
        this.$emit('update:value', this.s_value.join(''))
      }
      if (this.s_value.length === 4) {
        this.$emit('complete', this.s_value.join(''))
      }
    },
    backspace() {
      const value = [ ...this.s_value, ]
      value.pop()
      this.s_value = value
    },
    finger() {
      try {
        Fingerprint.isAvailable((result) => {
          Fingerprint.show(
            {
              title: 'Вход через отпечаток пальца',
              description: 'Приложите палец чтобы войти в приложение',
              disableBackup: true,
            },
            () => this.$emit('update:finger', true),
            () => this.$emit('update:finger', false)
          )
        })
        // eslint-disable-next-line no-empty
      } catch (e) {
        this.$emit('update:finger', false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-pin {
  display: flex;
  flex-direction: column;
  align-items: center;

  .danger-message {
    color: red;
  }
  .danger {
    border: 1px solid red !important;
  }

  .img-delete {
    width: 21px;
    height: 21px;
  }

  .finger-icon {
    width: 31px;
    height: 31px;
  }

  &.c-hidden {
    .pin-numbers {
      input {
        color: transparent;
      }
    }
  }

  .pin-numbers {
    margin: 0 0 18px;
    display: flex;
    align-items: center;
    input {
      width: 64px;
      height: 64px;
      padding: 5px;
      margin: 0 3px;
      font-weight: 300;
      background-color: #ffffff;
      color: #000000;
      border: 1px solid rgba(0,0,0,0.12);
      border-radius: 8px;
      text-align: center;
      font-size: 1.7em;
      &:focus-visible { outline: none; }
      &:focus { outline: none; }
      &::placeholder { opacity: 0; }
      &.filled {
        background-color: transparent;
        color: #000;
      }
    }
  }

  .inputs-subtitle {
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #6B6B6B;
    margin-bottom: 20px;

    &.discard {
      margin: 18px 0 0;
      text-align: right;
    }
  }

  .cancelPin {
    text-decoration: none;
    margin-top: 25px;
    font-size: 15px;
  }

  .keyboard {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: -7px;
    position: relative;
    max-width: 270px;

    .item {
      padding: 7px;
      &.hide {
        visibility: hidden;
      }
      .content {
        border-radius: 50%;
        background: #FFFFFF;
        width: 70px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 300;
        font-size: 33px;
        line-height: 47px;
        text-align: center;
        text-transform: uppercase;
        color: #000;

        &.icon{
          padding: 15px;

          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

// iphone 8
@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .c-pin{
    .pin-numbers {
      input{
        width: 50px;
        height: 50px;
      }
    }
  }
}
</style>
