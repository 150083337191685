<template>
  <div class="custom-checkbox">
    <input v-model="value" :checked="checked" type="checkbox">
    <div class="checkbox"></div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: this.checked,
    }
  },
  watch: {
    value() {
      this.$emit('update:value', this.value)
      this.$emit('change')
    },
  },
}
</script>

<style scoped lang="scss">
.custom-checkbox{
  position: relative;
  input{
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }

  input:checked + .checkbox{
    background: #FAAD14;
    border: 4px solid #FAAD14;

    &::after{
      content: '';
      background: #fff;
      left: 22px;
    }
  }

  .checkbox{
    width: 48px;
    height: 30px;
    border: 4px solid #8C8C8C;
    background: #fff;
    border-radius: 20px;
    transition: all .2s ease-in-out;
    position: relative;
    pointer-events: none;

    &::after{
      content: '';
      width: 17px;
      height: 17px;
      background: #8C8C8C;
      position: absolute;
      border-radius: 50%;
      pointer-events: none;
      top: 50%;
      transform: translate(0, -50%);
      left: 2px;
      right: auto;
      transition: all .2s ease-in-out;
    }
  }
}
</style>
