<template>
  <div class="c-call-app" :class="{'second': isModal}">
    <div class="modal-container" :class="{'second': isModal}">
      <div v-if="!isModal" class="wrap">
        <div class="top">
          <div class="left-side">
            <div class="title">{{ item.name }}</div>
          </div>
          <div class="right-side">
            <div class="close" @click="close">
              <n-icon icon="close" />
            </div>
          </div>
        </div>
        <div class="text">
          <div class="left-side">
            <n-icon icon="date" />
          </div>
          <div class="right-side">
            {{ $app.date.format(item.createdAt, 'date') }}
          </div>
        </div>
        <div class="text">
          <div class="left-side">
            <n-icon icon="volume" />
          </div>
          <div class="right-side">
            {{ item.volume }} кг
          </div>
        </div>
        <div class="text">
          <div class="left-side">
            <n-icon icon="author" />
          </div>
          <div class="right-side">
            {{ item.author?.fullname }}
          </div>
        </div>
        <div class="text">
          <div class="left-side">
            <n-icon icon="address" />
          </div>
          <div class="right-side">
            {{ item.address }}
          </div>
        </div>
        <div class="text">
          <div class="left-side">
            <n-icon icon="appDescription" />
          </div>
          <div class="right-side">
            {{ item.description }}
          </div>
        </div>
        <div class="text">
          <div class="left-side">
            <n-icon icon="appCategoryInfo" />
          </div>
          <div class="right-side">
            {{ item.categoryInfo }}
          </div>
        </div>
        <div class="button-place">
          <n-button class="call-button" icon="call" @click="showModal()"><t name="4.6" /></n-button>
        </div>
      </div>
      <div v-else class="wrap second">
        <div class="title">{{ item.name +' ' + item.volume +' кг' }}</div>
        <div class="title"><t name="4.7" /></div>
        <div class="buttons">
          <n-button class="is-call-button" @click="close"><t name="4.9" /></n-button>
          <n-button class="is-call-button yes" @click="accept"><t name="4.8" /></n-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CCallApp',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isModal: false,
    }
  },
  methods: {
    showModal() {
      this.isModal = true
    },
    accept() {
      this.$var('load', true)
      $api.reviews.create({
        appId: this.item.id,
        userId: $app.auth.user().id,
        isUserAccept: true,
      }).then(() => {
        this.$router.go(0)
      }).finally(() => {
        this.$var('load', false)
      })
    },
    close() {
      this.$emit('update:show', false)
    },
  },
}
</script>

<style scoped lang="scss">
.c-call-app {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: end;
  z-index: 999;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.32);
  &.second {
    align-items: center;
    justify-content: center;
  }
  .buttons {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .is-call-button {
      min-width: 115px;
      min-height: 44px;
      background-color: #FFFFFF;
      color: black;
      border: 1px solid #D9D9D9;
      &.yes {
        border: none;
        background-color: #FAAD14;
        color: #FFFFFF;
      }
    }
  }
  .button-place {
    display: flex;
    align-items: center;
    justify-content: center;
    .call-button {
      margin-top: 10px;
      background-color: #52C41A;
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
    }
  }
  .text {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .left-side {
      margin-right: 10px;
    }
  }
  .modal-container {
    z-index: 1000;
    min-height: 375px;
    width: 100vw;
    display: flex;
    justify-content: center;
    &.second {
      width: unset;
      min-height: 0;
      .title {
        text-align: center;
      }
      .title:first-child {
        font-weight: bold;
        font-size: 18px;
      }
    }
    border-radius: 6px;
    background-color: #FFFFFF;
    .wrap {
      width: 100%;
      padding: 18px;
      &.second {

      }
      .top {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-side {
          .title {
            color: #102E4F;
            font-size: 24px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
